<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="operations"
      class="elevation-0"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:no-data> Aucun opérations </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="toolbar-title">opérations </v-toolbar-title>
          <text-input
            prepend-inner-icon="mdi-magnify"
            @keyup="handleSearch"
            class="ml-5 mt-5"
            id="search"
            v-model="search"
            placeholder="Nom d'opération"
          />
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2"
                action_name="Ajouter"
                color="primary"
                text
                @clicked="openAddDialog"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          v-model="editedItem.name"
                          label="Nom d'opération*"
                          id="name"
                          :rules="requiredRules"
                        />
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  v-if="!showEditButton"
                  :loading="loading"
                />
                <Button
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  @clicked="save(false)"
                  v-if="!showEditButton"
                  :loading="loadingAdd"
                />
                <Button
                  action_name="Modifier"
                  color="#11887E"
                  text
                  @clicked="update(false)"
                  v-if="showEditButton"
                  :loading="loadingEdit"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Êtes vous sûr de supprimer cette ligne ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn
                  color="secondary darken-1"
                  text
                  @click="deleteLine"
                  :loading="loadingDelete"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Button, TextInput } from "@/components/base"
import {
  getAllOperations,
  searchOperation,
  addOperation,
  updateOperation,
  deleteOperation
} from "@/services/operations.api"
import { infinityScrollMixin } from "@/utils/infinityScrollMixin"
export default {
  components: { Button, TextInput },
  mixins: [infinityScrollMixin],
  data: () => ({
    search: "",
    loading: false,
    loadingAdd: false,
    loadingEdit: false,
    isValid: true,
    showEditButton: false,
    dialog: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Nom", value: "name" },
      { text: "Date de création", value: "created_at" },
      { text: "Date de modification", value: "updated_at"},
      { text: "Actions", value: "actions", sortable: false },
    ],
    operations: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    pageNumber: 1,
    last_page: 0,
    dialogDelete: false,
    loadingDelete: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau opération" : "Modifier opérations"
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1
        getAllOperations(this.pageNumber).then((response) => {
          this.operations = [...this.operations, ...response.data.data]
          this.last_page = response.data.last_page
        })
      }
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    handleSearch() {
      if (this.search !== "") {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          searchOperation(this.search).then((response) => {
            this.operations = response.data
          })
        }, 1000)
      } else {
        getAllOperations(this.pageNumber).then((response) => {
          this.operations = response.data
        })
      }
    },
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.dialog = true
    },
    initialize() {
      getAllOperations(this.pageNumber).then((response) => {
        this.operations = response.data
      })
    },
    close() {
      this.agentDialog = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.showEditButton = false
      })
    },
    update() {
      this.loadingEdit = true
      updateOperation(this.editedItem).then(() => {
        this.loadingEdit = false
        this.showEditButton = false
        Object.assign(this.operations[this.editedIndex], this.editedItem)
        this.initialize()
        this.close()
        this.$swal.fire({ title: "Operation modifier avec succès" })
      })
    },
    editItem(item) {
      this.showEditButton = true
      this.editedIndex = this.operations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate()
        return
      }
      dialogStat ? (this.loading = true) : (this.loadingAdd = true)

      addOperation(this.editedItem).then(() => {
        dialogStat ? (this.loading = false) : (this.loadingAdd = false)
        this.initialize()
        this.$refs.form.reset()
        this.dialog = dialogStat
        this.$swal.fire({ title: "Operation ajouté avec succès" })
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.editedItemID = this.editedItem.id
      this.dialogDelete = true
    },
    deleteLine() {
      this.loadingDelete = true
      deleteOperation(this.editedItemID).then(() => {
        this.loadingDelete = false
        this.initialize()
        this.closeDelete()
        this.$swal.fire({ title: "Ligne supprimer avec succés", timer: 800 })
      })
    },
  }
}
</script>
<style scoped></style>
